body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Processing Overlay */
.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(110, 107, 107, 0.8); /* Slightly transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Lower z-index */
}

.overlay-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  color: black; /* Ensure text is black */
}

/* Ensure that modals have a higher z-index */
.modal-backdrop.show {
  z-index: 1050; /* Bootstrap default */
}

.modal {
  z-index: 1060; /* Higher than processing-overlay */
}

.logged-out {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
}

.logged-out h2 {
  margin-bottom: 10px;
  color: #333;
}

.logged-out p {
  color: #666;
}
