/* Overall App Styling */
.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #282c34;
  color: white;
}

/* Header Styling */
.App-header {
  background-color: #282c34;
  padding: 20px 30px; /* Adjusted padding */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #61dafb;
}

.header-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Align logo and text to the left */
.header-left {
  display: flex;
  align-items: center;
}

.logo-container {
  margin-right: 20px; /* Space between logo and text */
}

.header-text h3 {
  margin: 0;
}

.header-text p {
  margin: 5px 0 0 0;
}

/* Move Logout Button to the top right */
.header-right {
  margin-left: auto;
}

.logout-button {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.logout-button:hover {
  background-color: #61dafb;
  color: white;
}

.logout-button svg {
  margin-right: 8px;
  stroke: white;
  transition: stroke 0.3s ease;
}

.logout-button:hover svg {
  stroke: white;
}

/* Main Content Area */
.App-main {
  flex-grow: 1;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  color: black;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Footer Styling */
.App-footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
  text-align: center;
  border-top: 2px solid #61dafb;
}
.logo {
  width: 150px; /* Set the desired width */
  height: auto; /* Maintain aspect ratio */
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
